import { IndexSource } from '@/domain/entities/Product';
import { FederatedSearchContext } from '@/infra/api/search/types/FederatedSearch';
import { getQueryId } from '@/infra/api/search/utils/trackingEvents';
import { Deal, OfferId } from '@/domain/entities/Offer';
import env from '@/config/environment';
import { AgreementType } from '@/domain/entities/Agreement';
import { getClientOperationMetadataInBase64 } from '@/infra/api/core/tracker';
import { Langs } from '@/locales';
import { hasPassedThroughSearch } from '@/infra/analytics/algolia';
import { ConfirmationEmailStatus } from '../../types/schema';
import transformToSlug from '../strings/transformToSlug';
import {
  ABOUT,
  ALERTS,
  ALL_STORES_URLS,
  CATEGORIES,
  COMFIRM_EMAIL,
  COMPARATOR,
  CONDUCT_CODE,
  CONTACT,
  COOKIES_POLICY,
  EDIT_OFFER,
  LOGIN,
  POST,
  PRIVACY_POLICY,
  PROMOS,
  SETTINGS,
  SIGN_UP,
  TERMS,
} from './consts';
import { getDupelandoOriginFlag } from '../dupelando';

/**
 * TODO: Quebrar este arquivo em vários separados por contexto
 * Este arquivo tem muitas funções, de muitos lugares diferentes,
 * não faz mais sentido manter apenas um arquivo.
 */

export const EXPIRED_DEAL_PARAM = 'expired_deal';

export const WHATSAPP_SHARE_URL = 'https://api.whatsapp.com/send';

export enum MetadataClient {
  SiteMobile = 'site_mobile',
  SiteDesktop = 'site_desktop',
  AppIphone = 'app_iphone',
  AppAndroid = 'app_android',
}

type CommonMetaParams = {
  userId?: string;
  componentSource?: ClickoutComponentSource;
  client?: MetadataClient;
  currentRelativeUrl: string;
  guestId?: string;
};

export enum ClickoutComponentSource {
  GetDealActionButton = 1,
  StorePageHiddenCoupon,
  StorePageGetDiscount,
  LinkOnVoucherModal,
  DealPageGetCoupon,
  DealPageGetDealButton,
  DealPageGetDealHeader,
  DealPageDescriptionLink,
  DealPageCommentLink,
  DealPageImage,
  DealPageSidebarGetDealButton,
  DealPageSidebarGetCouponButton,
  DealPageSidebarSeeInStoreButton,
  SecondaryDealCard,
  ProductSidebar,
  BlogList,
  ProductHeader,
  ProductComments,
}

export type GetOfferClickoutUrlParams = {
  dealId?: string;
  isTracked?: boolean;
  storeUrl?: string;
  sourceUrl?: string | null;
  recommendationId?: string;
};

export type GetOfferClickoutWithMetaParams =
  Partial<GetOfferClickoutUrlParams> &
    CommonMetaParams & {
      indexSource?: IndexSource;
      category_id?: string;
      queryId?: string;
    };

/* eslint-disable camelcase */
export type ClickoutQueryParams = {
  clickout_url_path: string;
  client?: MetadataClient;
  origin_offer_id?: string;
  user_id?: string;
  component_source?: ClickoutComponentSource;
  recommendationId?: string;
  guestId?: string;
};

export type ProductClickoutQueryParams = Omit<
  ClickoutQueryParams,
  'recommendationId'
> & {
  productId: string;
  storeName?: string;
  price?: number;
  ean13?: string;
  url: string;
  filters?: Record<string, unknown>;
  indexSource?: IndexSource;
  categoryId?: string;
};

export type GetProductClickoutWithMetaParams = ProductClickoutQueryParams;

export type GetBlogClickoutWithMetaParams = GetProductClickoutWithMetaParams;

export type ParamsGetDealUrl = {
  id: OfferId;
  title: string;
  recommendationId?: string;
  genericParams?: Record<string, string>;
};

export type ParamsGetDealUrlWithComment = Omit<ParamsGetDealUrl, 'id'> & {
  dealId: string;
  commentId: string;
};

export type GetStoreClickoutWithMetaParams = {
  storeId?: string;
  offerId?: string;
} & CommonMetaParams;

export type GetExternalLinkClickoutUrlWithMeta = {
  url: string;
  offerId?: string;
} & CommonMetaParams;

export const enum StoresTabQueryParam {
  DEALS = 'promocoes',
  COUPONS = 'cupons',
}

export const getCategoriesUrl = (locale: string = 'pt-BR') =>
  CATEGORIES[locale as Langs];

export const getWishlistUrl = (locale: string = 'pt-BR') =>
  ALERTS[locale as Langs];

export const getSavedDealsUrl = (locale: string = 'pt-BR') =>
  PROMOS[locale as Langs];

export const getSettingsUrl = (locale: string = 'pt-BR') =>
  SETTINGS[locale as Langs];

export const getSignupUrl = (locale: string = 'pt-BR') =>
  SIGN_UP[locale as Langs];

export const getLoginUrl = (locale: string = 'pt-BR') => LOGIN[locale as Langs];

export const getContactPelandoUrl = (locale: string = 'pt-BR') =>
  CONTACT[locale as Langs];

export const getAllStoresUrl = (locale: string = 'pt-BR') =>
  ALL_STORES_URLS[locale as Langs];

export const getComparatorHomeUrl = (locale: string = 'pt-BR') =>
  COMPARATOR[locale as Langs];

export const getConfirmEmailBaseUrl = (locale: string = 'pt-BR') =>
  COMFIRM_EMAIL[locale as Langs];

export const getConfirmEmailUrl = (status: ConfirmationEmailStatus) =>
  `${getConfirmEmailBaseUrl()}/${status as string}`;

export const getProductUrl = (
  productId: string,
  title: string,
  options?: { commentId?: string }
) => {
  const finalPath = `/p/${productId}/${transformToSlug(title)}`;
  const query = new URLSearchParams();

  if (options?.commentId) {
    query.append('comment_id', options.commentId);
  }

  const queryString = query.toString() ? `?${query.toString()}` : '';

  return `${finalPath}${queryString}`.toLocaleLowerCase();
};

/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const getCanonicalUrl = (pathname?: string) =>
  `${env.PUBLIC_BASE_CANONICAL_URL}${(pathname || '').replace(/\/$/, '')}`;

export const getContactUsUrl = () => 'https://ajuda.pelando.com.br';

export const getUserProfileUrl = (nickname: string) => `/perfil/${nickname}`;

export const getAgreementSlug = (
  agreementType: AgreementType,
  t: (field: string, ...variables: string[] | number[]) => string
) =>
  ({
    [AgreementType.TERMS_OF_SERVICE]: t('terms-of-use'),
    [AgreementType.PRIVACY_POLICY]: t('privacy-policy'),
    [AgreementType.CODE_OF_CONDUCT]: t('code-of-conduct'),
  }[agreementType]);

export const getAgreementUrl = (slug: string, locale: string) =>
  `${ABOUT[locale as Langs]}/${slug}`;

export const getAbsoluteClientUrl = (relativeUrl?: string) =>
  /* eslint-disable @typescript-eslint/restrict-template-expressions */
  `${env.PUBLIC_CLIENT_BASE_URL}${relativeUrl || ''}`;

export const getStoreRedirectUrl = (storeId: string) =>
  `${env.PUBLIC_REST_API_URL}/stores/${storeId}/redirect`;

export const getRedirectUrl = (url: string) =>
  `${env.PUBLIC_REST_API_URL}/redirect?url=${encodeURIComponent(url)}`;

export const getOffersRedirectUrl = (offerId: string) =>
  `${env.PUBLIC_REST_API_URL}/offers/${offerId}/redirect`;

/**
 * This silly combination of characters is used to easily identify clickouts
 * done by people using the beta client. This is safer and easier to aggregate
 * than other host-based methods.
 * do not remove/change without checking with our data team first.
 */
export const IGNIS_CLICKOUT_IDENTIFIER = 'ignis';

type MetaParams = ClickoutQueryParams & Record<string, unknown>;

export const encodeMetaObject = (meta: MetaParams): string =>
  encodeURIComponent(
    JSON.stringify({
      ...meta,
      sosho_app: IGNIS_CLICKOUT_IDENTIFIER,
      clientInfo: getClientOperationMetadataInBase64(),
    })
  );

export const getStoreClickoutUrlWithMeta = ({
  storeId,
  offerId,
  userId,
  client,
  currentRelativeUrl,
  guestId,
}: GetStoreClickoutWithMetaParams) => {
  if (!storeId) return null;

  const encodedMeta = encodeMetaObject({
    client,
    origin_offer_id: offerId,
    user_id: userId,
    clickout_url_path: currentRelativeUrl,
    guest_id: guestId,
    query_id: hasPassedThroughSearch()
      ? getQueryId(FederatedSearchContext.Store)
      : undefined,
    came_from_dpl: getDupelandoOriginFlag(),
  });

  const queryParams = `?metadata=${encodedMeta}`;

  const redirect = getStoreRedirectUrl(storeId);

  return `${redirect}${queryParams}`;
};

export const getExternalLinkClickoutUrlWithMeta = ({
  url,
  offerId,
  userId,
  client,
  currentRelativeUrl,
  guestId,
}: GetExternalLinkClickoutUrlWithMeta) => {
  const encodedMeta = encodeMetaObject({
    client,
    origin_offer_id: offerId,
    user_id: userId,
    clickout_url_path: currentRelativeUrl,
    guest_id: guestId,
  });

  const queryParams = `&metadata=${encodedMeta}`;

  const redirect = getRedirectUrl(url);

  return `${redirect}${queryParams}`;
};

export const getCouponsUrl = ({
  storeSlug,
  tab,
  locale = 'pt-BR',
}: {
  storeSlug: string;
  tab?: StoresTabQueryParam;
  locale?: string;
}) => {
  if (!tab) return `${ALL_STORES_URLS[locale as Langs]}/${storeSlug}`;

  const params = new URLSearchParams();

  params.set('tab', tab);

  const paramsAsString = params.toString();

  return `${ALL_STORES_URLS[locale as Langs]}/${storeSlug}?${paramsAsString}`;
};

export const getDealUrl = (
  { id, title, recommendationId }: ParamsGetDealUrl,
  genericParams?: Record<string, string>
) => {
  const slug = title ? `/${transformToSlug(title)}` : '';
  const searchParams = new URLSearchParams();

  if (recommendationId) {
    searchParams.set('recommendationId', recommendationId);
  }

  if (genericParams) {
    Object.keys(genericParams).forEach((key) => {
      searchParams.set(key, genericParams[key]);
    });
  }

  const paramsAsString = searchParams.toString();

  return paramsAsString
    ? `/d/${id}${slug}?${paramsAsString}`
    : `/d/${id}${slug}`;
};

export const getOfferRedirectUrl = (dealId: Deal['id']) =>
  `${env.PUBLIC_REST_API_URL}/offers/${dealId}/redirect`;

export const getOfferClickoutUrl = ({
  dealId,
  sourceUrl,
  isTracked,
  storeUrl,
}: GetOfferClickoutUrlParams) => {
  if (isTracked && dealId) {
    return getOfferRedirectUrl(dealId);
  }

  if (sourceUrl) {
    return sourceUrl;
  }

  if (storeUrl) {
    return storeUrl;
  }

  return null;
};

export const getDealUrlWithComment = ({
  commentId,
  dealId,
  title,
}: ParamsGetDealUrlWithComment) => {
  const dealUrl = getDealUrl({ id: dealId, title });
  return `${dealUrl}?comment=${commentId}`;
};

export const getAddOfferUrl = (locale: string = 'pt-BR') =>
  POST[locale as Langs];

export const getEditDealUrl = (id: string, locale: string = 'pt-BR') =>
  `${EDIT_OFFER[locale as Langs]}/${id}`;

export const getOfferClickoutUrlWithMeta = ({
  dealId,
  isTracked,
  sourceUrl,
  storeUrl,
  userId,
  componentSource,
  client,
  currentRelativeUrl,
  recommendationId,
  indexSource,
  category_id,
  guestId,
  queryId,
}: GetOfferClickoutWithMetaParams) => {
  const redirect = getOfferClickoutUrl({
    dealId,
    isTracked,
    sourceUrl,
    storeUrl,
  });

  if (!redirect) return null;

  let queryParams = '';

  if (isTracked) {
    const encodedMeta = encodeMetaObject({
      origin_offer_id: dealId,
      user_id: userId,
      component_source: componentSource,
      client,
      clickout_url_path: currentRelativeUrl,
      recommendationId,
      index_source: indexSource,
      category_id,
      guest_id: guestId,
      query_id: hasPassedThroughSearch()
        ? queryId || getQueryId(FederatedSearchContext.Deal)
        : undefined,
      came_from_dpl: getDupelandoOriginFlag(),
    });

    queryParams = `?metadata=${encodedMeta}`;
  }

  return `${redirect}${queryParams}`;
};

export const getPreviousUrl = () =>
  typeof window !== 'undefined' ? window.document.referrer : '';

export const getCurrentPathname = () =>
  typeof window !== 'undefined' ? window.location.pathname : '';

export const getProductRedirectUrl = (url: string, productId: string) => {
  const metadata = encodeURIComponent(
    JSON.stringify({
      productId,
      clientInfo: getClientOperationMetadataInBase64(),
    })
  );
  return `${getRedirectUrl(url)}&metadata=${metadata}`;
};

export const parseUrl = (url: string) => {
  try {
    const parsed = new URL(url);
    return parsed;
  } catch {
    return undefined;
  }
};

export const isInternalLink = (url: string): boolean => {
  if (typeof window === 'undefined') return false;

  const domain = parseUrl(url)?.hostname.replace('www', '') || '';
  const host = window?.location?.host || '';

  return host.includes(domain);
};

export const getCommentsSectionDealUrl = (dealUrl: string) =>
  `${dealUrl}#comments`;

export const getClickoutUrlWithMeta = (
  clickoutUrl: string,
  metadata: string
) => {
  const encodedMeta = encodeURIComponent(JSON.stringify(metadata));
  return `${clickoutUrl}?metadata=${encodedMeta}`;
};

export const getTwitterUrl = () => 'https://twitter.com/pelandobr';

export const getFacebookUrl = () => 'https://www.facebook.com/tapelando/';

export const getInstagramUrl = () => 'https://www.instagram.com/pelandobr/';

export const getTikTokUrl = () => 'https://www.tiktok.com/@pelandobr';

export const getBackofficeUrl = () => `${env.PUBLIC_BACKOFFICE_BASE_URL || ''}`;

export const getEditDealOnBackofficeUrl = (dealId: string) =>
  `${getBackofficeUrl()}/deals-moderation/${dealId}/show`;

export const getPelandoHelpCenterUrl = (locale: string) =>
  locale === 'pt-BR'
    ? 'https://ajuda.pelando.com.br'
    : 'https://help.promoninja.com';

export const getTermsOfServiceUrl = (locale: string = 'pt-BR') =>
  `${ABOUT[locale as Langs]}${TERMS[locale as Langs]}`;

export const getConductCodeUrl = (locale: string = 'pt-BR') =>
  `${ABOUT[locale as Langs]}${CONDUCT_CODE[locale as Langs]}`;

export const getPrivacyPolicyUrl = (locale: string = 'pt-BR') =>
  `${ABOUT[locale as Langs]}${PRIVACY_POLICY[locale as Langs]}`;

export const getPolicyCookiesUrl = (locale: string = 'pt-BR') =>
  `${ABOUT[locale as Langs]}${COOKIES_POLICY[locale as Langs]}`;

export const getUserbackFeedbackUrl = () =>
  'https://pelando-site.ideas.userback.io/p/9RjeK84h82';

export const getProductClickoutUrlWithMeta = ({
  clickout_url_path,
  client,
  component_source,
  ean13,
  origin_offer_id,
  price,
  productId,
  storeName,
  user_id,
  url,
  filters,
  indexSource,
  categoryId,
  guestId,
}: GetProductClickoutWithMetaParams) => {
  const encodedMeta = encodeMetaObject({
    origin_offer_id,
    user_id,
    component_source,
    client,
    clickout_url_path: clickout_url_path || '',
    productId,
    externalOfferId: origin_offer_id,
    storeName,
    price,
    ean13,
    filters,
    index_source: indexSource,
    category_id: categoryId,
    guest_id: guestId,
    query_id: hasPassedThroughSearch()
      ? getQueryId(FederatedSearchContext.Product)
      : undefined,
    came_from_dpl: getDupelandoOriginFlag(),
  });

  const queryParams = `&metadata=${encodedMeta}`;

  const redirect = getRedirectUrl(url);

  return `${redirect}${queryParams}`;
};

export const URL_APP = 'https://onelink.to/downloadapptoast';
export const URL_APP_LOGIN = 'https://onelink.to/downloadapplogin';
export const URL_APP_BOTTOM_SHEET = 'https://onelink.to/downloadappdeals';
export const URL_APP_ONBOARING = 'https://onelink.to/downloadapponb';
export const URL_APP_BANNER_DEALS = 'https://onelink.to/downloadappcoment';
export const URL_APP_CARD_FIXED = 'https://onelink.to/cardfixomobile';

export const getWhatsAppShareUrl = (text: string) => {
  const encodedText = encodeURIComponent(text);
  return `${WHATSAPP_SHARE_URL}?text=${encodedText}`;
};

export const getProductionApiUrl = () => 'https://api.pelando.com.br';

export const getBlackFridayUrl = (storeSlug?: string) => {
  const searchParams = new URLSearchParams();

  if (storeSlug) {
    searchParams.append('store', storeSlug);
  }

  const query = searchParams.toString() ? `?${searchParams.toString()}` : '';

  return `/black-friday${query}`;
};
